<!-- 高德地图 -->
<template>
    <div id="container"></div>
  </template>
  
  <script >
  import AMapLoader from "@amap/amap-jsapi-loader";
  window._AMapSecurityConfig = {
        securityJsCode: "56f21d0b10d59d272acc609098c30fbf",
      };
  export default{
    props:{
      mapData: {
        //中心点
        type: Object,
        required: false,
        default: ()=>{},
      },
    type: {
      type: String,
      default: "",
    },
    height:{
      type: String,
      default: "calc(100vh - 50px)",
    },
    path: {
      type: Array,
      default: () => [],
    },
    name: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
    data(){
        return{
            map:null,
            markers:null,
            infoWindow:null,
            switchList:[
        require("@/assets/index/open.png"),
        require("@/assets/index/close.png"),
      ],
      markersList:[
       require("@/assets/index/point1.png"),
       require("@/assets/index/czpost1.png"),
       require("@/assets/index/czpost2.png"),
       require("@/assets/index/czpost3.png"),
       require("@/assets/index/czpost4.png"),
       require("@/assets/index/czpost5.png")
    ],
    groupByValue: []
        }
    },
 


  mounted() {
    this.mapInit();
  },
  destroyed() {
        //this.map.destroy();
        this.map = null
  },
  methods:{
     // 地图初始化
   mapInit () {
    AMapLoader.reset()  // 关键点-----解决catch里高德报错 (禁止多种API加载方式混用)
    AMapLoader.load({
      key: "23d95d6620fa5bd2e8da3f61be5de237",
      version: "2.0",
      plugins: [
        "AMap.Scale",
        "AMap.MouseTool",
        "AMap.Marker",
        "AMap.ContextMenu",
        "AMap.Polygon",
        "AMap.GeoJSON",
      ],
    })
      .then((AMap) => {
        this.map = new AMap.Map("container", {
         resizeEnable: true,
          viewMode: "3D",
          zoomEnable: true,
          dragEnable: true, 
          doubleClickZoom: true, 
          zoom: this.mapData.zoom||11, // 初始化地图级别
          layers: [new AMap.TileLayer.Satellite()],
          //center: [109.205343,34.639516]
          center: this.mapData.centerPosition.split(","), // 初始化地图中心点位置 [109.205343,34.639516]
        });
        this.drawJson();
        this.setMapMarker()
        this.map.addControl(new AMap.Scale())


     
      
        // this.viewCoordinate()

        }).catch(e => {
                //console.log(e);
            })
      },

    async drawJson() {
      // 设置地图zoom值
      if (this.mapData.zoom) this.map.setZoom(this.mapData.zoom)
      if (this.mapData.polygonWkt) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(this.mapData.polygonWkt), // 设置多边形坐标数组
          strokeColor: "#0bc0b8", // 线条颜色
          strokeWeight: 4, //线条宽度
          fillColor: "#000", // 填充颜色
          fillOpacity: 0.2, // 填充透明度
        });
        polygon.setMap(this.map);

      }
      // 绘制标记

      console.log("绘制标记：",this.mapData.baseList)
      const makerList = []
      this.mapData.baseList.forEach(v=>{
        const lat1 = v.centerPos && v.centerPos !== "[]" ? JSON.parse(JSON.stringify(v.centerPos)).split(','): '';
        console.log(lat1)

        if (lat1 && lat1.length>0) {
          const marker = new AMap.Marker({
            text:"name",
            position: new AMap.LngLat(lat1[0],lat1[1]),
            offset: new AMap.Pixel(-10, -10),
            icon: require("@/assets/index/fish.png"),
            zooms: [5,18],
          });
          marker.setLabel({
            content: v.baseName,
            direction: top,
          })
          marker.on("click", () => {
            this.$emit('onMarkerChange', v.id)
          });
          makerList.push(marker)
        }

      })
      console.log("点点点点点",makerList)
      this.map.add(makerList);



      //绘制geojson
      if (this.mapData.geoJson) {
        await fetch(`/geojson/${this.mapData.geoJson}.json`)
            .then((response) => response.json())
            .then(async (data) => {
              let dataGeo = [];
              await new AMap.GeoJSON({
                geoJSON: data,
                getPolygon: function (geojson, lnglats) {
                  dataGeo = lnglats[0];
                },
              });
              var polygon = new AMap.Polygon({
                path: dataGeo, // 设置多边形坐标数组
                strokeColor: "yellow", // 线条颜色
                strokeWeight: 4, //线条宽度
                fillColor: "red", // 填充颜色
                fillOpacity: 0.1, // 填充透明度
              });
              polygon.setMap(this.map);

            })
            .catch((error) => {
              console.error("Error loading data:", error);
            });
        // 创建纯文本标记
        /*const lat = this.mapData.centerPosition.split(",");
        var text = new AMap.Text({
          text:this.mapData.mapName,
          anchor:'center', // 设置文本标记锚点
          draggable:false,
          cursor:'pointer',
          angle:0,
          style:{
            'padding': '.75rem 1.25rem',
            'margin-bottom': '1rem',
            'border-radius': '.25rem',
            'background-color': 'rgba(0,0,0,0)',
            'width': '15rem',
            'border-width': 0,
            'text-align': 'center',
            'font-size': '14px',
            'color': 'yellow',
            'font-weight':'bold',
          },
          position: new AMap.LngLat(lat[0], lat[1]),
        });

        text.setMap(this.map);*/

      }
    },
      setMapMarker(){
        const makerList = []
        console.log('mapData.baseList', this.mapData.baseList)
        this.mapData.baseList.forEach(v=>{
          const lat = v.centerPos && v.centerPos !== "[]" ? JSON.parse(v.centerPos) : ''
          if (lat && lat.length>0) {
            const marker = new AMap.Marker({
              text:"name",
              position: new AMap.LngLat(lat[0],lat[1]),
              offset: new AMap.Pixel(-10, -10),
              icon: require("@/assets/index/czpost1-min.png"),
              zooms: [5,18]
            });
            marker.setLabel({
              content:v.baseName,
              direction:top,
            })
            marker.on("click", ()=>{
              this.$emit('onMarkerChange', v.id)
            });
            makerList.push(marker)
          }
         
        })
        this.map.add(makerList);
        // this.map.setFitView()
      },
     viewCoordinate () {
      //console.log(this.path,'======this.path')
        if (this.path.length > 0) {
          try {
            this.path.map((item) => {
              let lengths = item.centerPos.length;
              let centerPos = item.centerPos.substring(1, lengths-1);
              //console.log(centerPos)
            // if(item.deviceLocation && item.deviceLocation.includes(',')) { 
            if(centerPos && centerPos.includes(',')) {
            let icon = this.markersList[0];
            // if(item.deviceType==='1'){
            //     icon=this.markersList[3]
            // }else if(item.deviceType==='2'){
            //     icon=this.markersList[1]
            // }else if(item.deviceType==='3'){
            //     icon=this.markersList[4]
            // }else if(item.deviceType==='5'){
            //     icon=this.markersList[2]
            // }else if(item.deviceType==='6'){
            //     icon=this.markersList[0]
            // }
            const lat = centerPos.split(',')
            const markerPosition = new AMap.LngLat(lat[0], lat[1]);
            this.markers = new AMap.Marker({
              map: this.map,
              position: markerPosition,
              strokeColor: "#FFEB16",
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeStyle: "solid",
              icon: icon,
              // size: new AMap.Size(30, 10),
              offset: new AMap.Pixel(-6, -4),
              title:item.type
            });
           }
          });
          this.markers.on("click", this.handlerPath);
          } catch (error) {
            
          }
          
         
        }
      },
      handlerPath(e){
        const { target } = e;
        const { lng, lat } = target.getPosition();
        //console.log(target.getPosition(),'======获取路径',target.getOptions())
      }    
    }
  
}

  </script>
  <style lang="less">
  #container{width: 100%;height: 100%;}
  .custom-content-marker {
            position: relative;
            width: 1px;
            height: 1px;
        }

        .custom-content-marker img {
            width: 1px;
            height: 1px;
        }
  .amap-marker-label{
    color: #ffffff;
    border:1px solid #fff;
    font-weight: bold;
    background-color: rgb(2, 156, 252);


  }

  </style>
  